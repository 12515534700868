import React from 'react';
import Countdown from 'react-countdown';

import './Stage.scss';

class Stage extends React.Component {

    render() {
        let stage, current_stage, stage_details, content;

        const CountdownCompleted = () => <span>end</span>;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                return <CountdownCompleted />;
            }
            else {
                hours = days * 24 + hours;
                return <span className="countdown"><strong>{hours}</strong>h:<strong>{minutes>9?minutes:'0'+minutes}</strong>m:<strong>{seconds>9?seconds:'0'+seconds}</strong>s</span>;
            }
        };

        if (this.props.stage && this.props.stagesAttributes) {
            stage = this.props.stage;
            current_stage = this.props.currentStage;

            switch (this.props.stage) {
                default:
                case 1:
                    stage_details = this.props.stagesAttributes.stage_1;
                    break;

                case 2:
                    stage_details = this.props.stagesAttributes.stage_2;
                    break;

                case 3:
                    stage_details = this.props.stagesAttributes.stage_3;
                    break;
            }
        }

        if (current_stage && stage === current_stage && stage !== 3) {
            content = (
                <div className="progress">
                    <Countdown
                        date={new Date(stage_details.end_date)}
                        renderer={renderer}
                    />
                </div>
            );
        }
        else {
            content = (
                <div className="progress">
                    <p>{stage_details ? stage_details.start_date_label : null}<br/>{stage_details ? stage_details.end_date_label : null}</p>
                </div>
            );
        }

        return (
            <div className="stage-header">
                <div className="small-top-lines dark">
                    { content }
                </div>
            </div>
        )
    }
}

export default Stage;