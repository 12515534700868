import React from "react";
import './MintProgress.scss';


let totalMinted;

class MintProgress extends React.Component {

    render() {
        if ( this.props.totalTokens ) {
            if ( this.props.totalTokens.json.data[0].tokens) {
                totalMinted = this.props.totalTokens.json.data[0].tokens.minted;
            }
        }
        //let percentage = this.props.minted / this.props.supply * 100;
        return (
            <div className="mint-progress">
                <div className="label">Minted: {totalMinted}</div>
                {/* <div className="label">Minted: {this.props.minted} of {this.props.supply}</div>
                <div className="progress">
                    <div className="percentage" style={{width: percentage + '%'}}></div>
                </div> */}
            </div>
        )

    }
}

export default MintProgress;