import _ from "lodash";

function fetchDefaultHeaders(headers = {}) {
    const defaultHeaders = {
        headers: {
            "Api-Key": process.env.REACT_APP_API_KEY
        }
    }

    return _.merge(defaultHeaders, headers);
}

const api = {
    mint: (address, data) => {
        return fetch(process.env.REACT_APP_API_BASE_URL + '/nft/merkle/' + address, fetchDefaultHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })).then(res => res.json());
    },
    list: (address, stage_id) => {
        return fetch(process.env.REACT_APP_API_BASE_URL + '/nft/list/' + address + '/' + stage_id, fetchDefaultHeaders())
            .then(res => res.json());
    },
    collectionVial: () => {
        return fetch(process.env.REACT_APP_API_BASE_URL + '/nft/collection/' + process.env.REACT_APP_NFT_COLLECTION_VIAL_ID, fetchDefaultHeaders())
            .then(res => res.json());
    }
}

export default api;
