import React from 'react';
import Countdown from 'react-countdown';
import Stage from '../components/Stage/Stage';
import List from '../components/List/List';
import Spinner from '../components/Spinner/Spinner';
import ListRecap from '../components/ListRecap/ListRecap';
import Api from '../api/Api';

import parse from 'html-react-parser';
import {isMobile} from 'react-device-detect';

import './Mint.scss';

// Stages
const totalStages = 3;
let lists_by_stage = [];

class Mint extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            metamask: window.ethereum,
            selectedAccount: null,
            apiListsResponse: null,
            currentStage: null,
            isSoldout: null,
            lists_by_stage: [],
            collectionTitle: null,
            stagesAttributes: null,
            labels: [],
            spinner: false,
            loadedStages: 0,
        };

        this.loadCollectionVial = this.loadCollectionVial.bind(this);
    }

    componentDidMount() {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
                this.setState({
                    metamask: window.ethereum,
                    selectedAccount: accounts[0]
                });

                this.loadCollectionVial();
            });
        }
        else {
            this.loadCollectionVial();
        }
    };

    loadCollectionVial() {
        this.setState({ spinner: true });

        Api.collectionVial().then((json) => {
            if (json.status === 'success') {
                this.setState({
                    collectionTitle: json.data.title,
                    stagesAttributes: json.data.stages_attributes,
                    currentStage: json.data.stage,
                    isSoldout: json.data.is_soldout,
                    labels: json.data.labels
                });

                if (process.env.REACT_APP_STATUS !== "0") {
                    for (let stage = 1; stage <= totalStages; stage++) {
                        Api.list(this.state.selectedAccount, stage).then((json) => {
                            lists_by_stage[stage] = {...lists_by_stage[stage], json: json};
                            this.setState({ lists_by_stage });
                            this.setState({ loadedStages: (this.state.loadedStages + 1) })
                            if (this.state.loadedStages === totalStages) {
                               this.setState({ spinner: false });
                            }
                        });
                    }
                }
            }
            else if (json.status === 'error') {
                // manage API errors
            }
        });
    }

    render() {
        let content, stages, activation;

        const CountdownCompleted = () => <span>{ this.state.labels._VOLTZ_MINT_IS_OPEN_TITLE ? this.state.labels._VOLTZ_MINT_IS_OPEN_TITLE : null }</span>;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <CountdownCompleted />;
            }
            else {
                // Render a countdown
                return <span className="countdown"><strong>{days}</strong>d:<strong>{hours}</strong>h:<strong>{minutes>9?minutes:'0'+minutes}</strong>m:<strong>{seconds>9?seconds:'0'+seconds}</strong>s</span>;
            }
        };

        if (this.state.currentStage < 3) {
            activation = false;
            if (String(this.state.selectedAccount).toUpperCase() === '0x4377BEEF614c12dCc814bE4cAa7Cd732a2a48d89'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0xF689FCe02340191472d5723A9381050d52995856'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0x2843afe4a48277658c7841e7c90325700f42ed79'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0x1BF341B75A5a346B1A91895C4EC50352cB00108B'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0xB8B7DC5b439f72154DafC2c13376D861E0C19513'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0xD347d913f6C500F18E75BB0F5192899faf4853c2'.toUpperCase() ) {
                activation = true;
            }

            stages = (
                <div className="uk-grid-small uk-flex uk-flex-center" data-uk-grid>
                    {this.state.lists_by_stage.map((stage, index) => (
                        <div className="uk-width-1-3@m" key={index}>
                            <Stage stage={index} currentStage={this.state.currentStage} stagesAttributes={this.state.stagesAttributes} />
                            <div className={ parseInt(this.state.currentStage) === index ? "stage active" : "stage"}>
                                <List lists={stage} selectedAccount={this.state.selectedAccount} status={parseInt(this.state.currentStage) === index ? "active" : (activation ? "active" : "inactive")} soldoutLabel={this.state.labels._VOLTZ_MINT_THIS_LIST_IS_SOLD_OUT} soldoutPublicLabel={this.state.labels._VOLTZ_MINT_SOLD_OUT} refreshLabel={this.state.labels._VOLTZ_MINT_RELOAD_AFTER_TX} totalTokens={this.state.lists_by_stage[3]} />
                            </div>
                            { this.state.currentStage === index ? <div className="vial-image"><img src={"./img/voltz-vial.png"} alt="VOLTZ Vial" /></div> : null }
                        </div>
                    ))}
                </div>
                );
        }
        else if (this.state.currentStage === 3 && this.state.lists_by_stage[this.state.currentStage]) {
            stages = (
                <div className="uk-grid-medium uk-flex uk-flex-center" data-uk-grid>
                    <div className="uk-width-1-2@m">
                        <Stage stage={this.state.currentStage} currentStage={this.state.currentStage} stagesAttributes={this.state.stagesAttributes} />
                        <div className="stage active">
                            <List lists={this.state.lists_by_stage[this.state.currentStage]} selectedAccount={this.state.selectedAccount} status={"active"} soldoutPublicLabel={this.state.labels._VOLTZ_MINT_SOLD_OUT} refreshLabel={this.state.labels._VOLTZ_MINT_RELOAD_AFTER_TX} />
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <ListRecap listsByStage={this.state.lists_by_stage} labelMinted={this.state.labels._VOLTZ_MINT_MINTED} />
                        <div className="vial-image"><img src={"./img/voltz-vial.png"} alt="VOLTZ Vial" /></div>
                    </div>
                </div>
            );
        }

        if (this.state.isSoldout) {
            content = (
                <div className="uk-container uk-margin-large-bottom">
                    <div className="top-title">{ this.state.labels._VOLTZ_MINT_SOLD_OUT }</div>
                    <div className="top-subtitle">{ parse(this.state.labels._VOLTZ_MINT_SOLD_OUT_MESSAGE) }</div>
                    <ListRecap listsByStage={this.state.lists_by_stage} labelMinted={this.state.labels._VOLTZ_MINT_MINTED} />
                </div>
            );
        }
        else {
            content = (
                <div>
                    {
                    this.state.currentStage === 0 ?
                        <div className="uk-container uk-margin-medium-bottom">
                            <div className="top-title"><Countdown date={this.state.stagesAttributes ? new Date(this.state.stagesAttributes.stage_1.start_date) : null} renderer={renderer} /></div>
                            <div className="top-subtitle">{ parse(this.state.labels._VOLTZ_MINT_COUNTDOWN_TO_OPEN_MESSAGE) }</div>
                            { this.state.selectedAccount ? <div className="eth-address-wrapper"><div className="eth-address">{ this.state.selectedAccount }</div></div> : null }
                        </div> :
                        <div className="uk-container uk-margin-medium-bottom">
                            <div className="top-title">{ this.state.labels._VOLTZ_MINT_IS_OPEN }</div>
                            { this.state.selectedAccount ?
                                <div className="top-subtitle">{ isMobile ? this.state.labels._VOLTZ_MINT_MOBILE_WARNING : null }</div> :
                                <div className="eth-address-wrapper"><div className="eth-address">{ this.state.labels._VOLTZ_MINT_IS_OPEN_CONNECT_WALLET_TO_MINT }</div></div>
                            }
                            { this.state.selectedAccount ? <div className="eth-address-wrapper"><div className="eth-address">{ this.state.selectedAccount }</div></div> : null }
                        </div>
                    }
                    { process.env.REACT_APP_STATUS !== "0" ?
                    <div>
                        <div className="uk-container uk-margin-medium-bottom">
                            {stages}
                        </div>
                    </div>
                    : null }
                </div>
            );
        }

        return (
            <div className="view">
                { this.state.spinner && ( <Spinner /> )}
                <div className="uk-container uk-text-center collection-title">
                    <div>
                        <h1>{ this.state.labels._VOLTZ_MINT_TITLE }</h1>
                    </div>
                </div>

                { content }

            </div>
        );
    }

}

export default Mint;
