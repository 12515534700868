import React from "react";
import './ListRecap.scss';

import parse from 'html-react-parser';

class ListRecap extends React.Component {

    render() {

        return (
            <ul className="list-recap">
                {this.props.listsByStage ? this.props.listsByStage.map((list, index) => (
                    <li key={index}>{
                        (list.json.data[0] && list.json.data[0].nfts ?
                            (list.json.data[0].nfts.minted ? parse("<div className='recap-content'><div className='recap-title'>" + list.json.data[0].title + "</div><div className='recap-minted'><strong>" + this.props.labelMinted + ": " + list.json.data[0].nfts.minted + "</strong></div></div>") : null)
                        : null)}</li>
                )) : null}
            </ul>
        )

    }
}

export default ListRecap;