import React from 'react';
import Button from './../../components/Button/Button';
import Api from '../../api/Api';
import parse from 'html-react-parser';

import './MintButton.scss';

class MintButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nft_amount: this.props.list === 4 ? 1 : (this.props.max ? this.props.max : 1),
            metamask: window.ethereum,
            apiMintResponse: null,
            mint_in_progress: false,
            minted: false
        };

        this.mintVial = this.mintVial.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    componentDidMount() {
        window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
            this.setState({
                metamask: window.ethereum,
                selectedAccount: accounts[0],
                selectedChain: window.ethereum.chainId
            });
        });
    };

    mintVial(list_id = 0) {
        let self = this;
        let nft_unit_price;
        if (list_id !== 0) {

            this.setState({
                mint_in_progress: true,
                apiMintResponse: false
            });

            switch (list_id) {
                default:
                    nft_unit_price = parseInt(process.env.REACT_APP_CHAIN_ID) === 1 ? 50000000000000000 : 500000000000000;
                    break;
                case 3:
                    nft_unit_price = 0;
                    break;
                case 4:
                    nft_unit_price = parseInt(process.env.REACT_APP_CHAIN_ID) === 1 ? 75000000000000000 : 1500000000000000;
                    break;
            }

            Api.mint(this.state.selectedAccount, {
                amount: this.state.nft_amount,
                list_id: list_id
            }).then(async (json) => {

                if (json.status === 'error') {
                    self.setState({
                        apiMintResponse: json.message
                    });
                }
                else {
                    const value = nft_unit_price * this.state.nft_amount;
                    const gas = 340000 + (this.state.nft_amount*20000);
                    let transactionParameters = {
                        gas: '0x' + gas.toString(16),
                        to: json.data.contract,
                        from: this.state.selectedAccount,
                        value: '0x' + value.toString(16),
                        data: json.data.tx_data
                    };

                    if (json.data.gas_oracle.ProposeGasPrice && parseInt(process.env.REACT_APP_CHAIN_ID) === 1) {
                        let gasPrice = json.data.gas_oracle.ProposeGasPrice * Math.pow(10, 9);
                        gasPrice = gasPrice.toString(16);
                        transactionParameters['gasPrice'] = '0x' + gasPrice;
                    }

                    // txHash is a hex string
                    // As with any RPC call, it may throw an error
                    const txHash = await this.state.metamask.request({
                        method: 'eth_sendTransaction',
                        params: [ transactionParameters ],
                    });

                    // console.log("transaction: ", txHash);

                    if (window.ethereum.chainId === '0x4') {
                        self.setState({
                            apiMintResponse: '<a href="https://rinkeby.etherscan.io/tx/' + txHash + '" target="_blank"  rel="noreferrer">https://rinkeby.etherscan.io/tx/' + txHash + '</a><br/><br/><small>' + this.props.refreshLabel + '</small>',
                            minted: true
                        });
                    }
                    else {
                        self.setState({
                            apiMintResponse: '<a href="https://etherscan.io/tx/' + txHash + '" target="_blank"  rel="noreferrer">https://etherscan.io/tx/' + txHash + '</a><br/><br/><small>' + this.props.refreshLabel + '</small>',
                            minted: true
                        });
                    }
                }

                this.setState({
                    mint_in_progress: false
                });
            }).catch(function(e) {
                self.setState({
                    apiMintResponse: e.message,
                    mint_in_progress: false
                });

                console.log(e); // "oh, no!"
            });
        }
    };

    get nft_amount() {
        return this.state.nft_amount;
    }

    increment() {
        let max_mintable;
        const { max, availability } = this.props;

        if (availability < max) {
            max_mintable = availability;
        }
        else {
            max_mintable = max;
        }

        if (typeof max === 'number' && this.nft_amount >= max_mintable) return;
        this.setState({ nft_amount: this.nft_amount + 1 });
    }

    decrement() {
        const { min } = this.props;
        if (typeof min === 'number' && this.nft_amount <= min) return;
        this.setState({ nft_amount: this.nft_amount - 1 });
    }

    render() {
        let mint_button, spinner, max_mintable;

        if (this.props.max && this.props.availability > 0) {
            if (this.props.availability < this.props.max) {
                max_mintable = this.props.availability;
            }
            else {
                max_mintable = this.props.max;
            }

            if (parseInt(this.state.selectedChain) === parseInt(process.env.REACT_APP_CHAIN_ID)) {
                mint_button = (
                    <div className="actions">
                        <div className="input-number" style={this.props.style}>
                            <button type="button" onClick={this.decrement} className={this.props.min < max_mintable ? "decrement" : "decrement disabled"}></button>
                            <span>{(this.nft_amount ? this.nft_amount : this.props.min) + " / " + max_mintable }</span>
                            <button type="button" onClick={this.increment} className={this.props.min < max_mintable ? "increment" : "increment disabled"}></button>
                        </div>
                        <Button label="Mint" onClick={() => this.mintVial(this.props.list)} />
                    </div>
                );
            }
            else {
                mint_button = (
                    <div className="list-sold-out">Connected to wrong chain</div>
                );
            }
        }

        spinner = (
            <div className="actions">
                <div data-uk-spinner></div>
            </div>
        );

        return (
            <div>
                { this.state.minted ? null : <p className="comment">{this.props.comment ? parse(this.props.comment) : null}</p> }
                { this.state.mint_in_progress ? spinner : (this.state.minted ? null : mint_button) }
                { this.props.max && this.props.availability > 0 ? <div className="tx-output">{ this.state.apiMintResponse ? parse(this.state.apiMintResponse) : null }</div> : null }
            </div>
        )
    }
}

export default MintButton;