import React from "react";
import MintButton from './../../components/MintButton/MintButton';
import MintProgress from './../../components/MintProgress/MintProgress';
import parse from 'html-react-parser';
//import Api from './../../api/Api';

import './List.scss';

class List extends React.Component {

    render() {
        let selectedAccount;
        selectedAccount = this.props.selectedAccount ? this.props.selectedAccount : null;
        return (
            <ul className="list">
                {this.props.lists ? this.props.lists.json.data.map((list, index) => (
                    <li className={list.available === true ? "available" : "unavailable"} key={index}>
                        { (list.id === 1) && this.props.status === 'active' ? <MintProgress minted={list.tokens.minted} supply={list.tokens.supply} totalTokens={this.props.totalTokens} /> : null }
                        <h3 className="title">{list.title ? parse(list.title) : null}</h3>
                        <div className="divider"></div>
                        { selectedAccount && this.props.status === 'active' && list.available === true && list.nfts.available ? <MintButton min={1} max={list.nfts.available} availability={list.tokens.availability} list={list.id} comment={list.comment} refreshLabel={this.props.refreshLabel} /> : <p className="comment">{selectedAccount && list.comment ? parse(list.comment) : null}</p> }
                        { list.tokens.availability === 0 && list.id !== 4 ? <div className="list-sold-out">{this.props.soldoutLabel}</div> : null }
                        { list.tokens.availability === 0 && list.id === 4 ? <div className="list-sold-out">{this.props.soldoutPublicLabel}</div> : null }
                    </li>
                )) : null}
            </ul>
        )

    }
}

export default List;