import React from 'react';
import './Button.scss';

class Button extends React.Component {
    render() {
        if (this.props.onClick) {
            return (
                <div className="button" onClick={ this.props.onClick }>{ this.props.label }</div>
            );
        }
        else if (this.props.href) {
            return (
                <a
                    className="button"
                    href={ this.props.href }
                    target={ this.props.target ? this.props.target : '_blank' }
                    rel="noreferrer"
                >{ this.props.label }</a>
            );
        }
        else {
            return (
                <div className="button">{ this.props.label }</div>
            )
        }
    }
}

export default Button;